<template>
    <div class="orderItem" @click="goToDetail">
        <div class="head van-hairline--bottom">
            <div class="left">订单编号：{{ item.orderSn }}</div>
            <div
                :class="{
                    state: true
                }"
            >
              {{item.orderStatusName=='无'?'':item.orderStatusName }} {{item.orderRstatusName && item.orderRstatusName!='无'? '|'+item.orderRstatusName:''}}
            </div>

        </div>

        <div class="goodsList">
            <div class="baseInfo"  v-if="item.orderSaleProducts.length === 1">
                <div class="name">
                  <span class="ys" v-show="item.orderSaleProducts[0].libckflag ===0">预售</span>
                  <span class="xh" v-show="item.orderSaleProducts[0].libckflag ===1">现货</span>
                  {{ item.orderSaleProducts[0].name }}
                </div>
            </div>
            <!-- 单个商品的 -->
            <div class="goodsInfo" v-if="item.orderSaleProducts.length === 1">
                <img
                    :src="item.orderSaleProducts[0].pic"
                    alt=""
                />
                <div class="right">
                    <div class="sku">数量：{{ item.orderSaleProducts[0].num }}</div>
                    <div class="tagList">
                        <div class="tag" v-show="item.orderSaleProducts[0].deliveryMode ==='2'">配送到校</div>
                        <div class="tag" v-show="item.orderSaleProducts[0].deliveryMode ==='1'">快递到家</div>
                    </div>
<!--                    <div class="time"-->
<!--                         v-show="item.orderSaleProducts[0].saleProduct.deliverGoodsTemp === '1'">-->
<!--                      下单{{item.orderSaleProducts[0].saleProduct.day}}天内发货：-->
<!--                    </div>-->
<!--                    <div class="time"-->
<!--                         v-show="item.orderSaleProducts[0].saleProduct.deliverGoodsTemp === '2'">预计配送时间：{{ item.orderSaleProducts[0].saleProduct.deliverGoodsContent }}</div>-->
                </div>
            </div>
            <!-- 多个商品的 -->
            <div class="goodsInfo multiple" v-else>
                <div class="imgBox" v-for="goods in item.orderSaleProducts">
                    <img

                        :src="goods.pic"
                        alt=""
                    />
                    <div class="num">x{{goods.num}}</div>
                </div>

                <div class="more" v-if="item.orderSaleProducts.length > 3">
                    <span>查看更多</span>
                    <img src="./../../../assets/images/order/submitOrder/arrow.png" alt="">
                </div>
            </div>
            <div class="orderInfo">
                <span>实付:</span>
                <span class="price">
                    <i class="unit">¥</i>
                    <i class="money">{{ item.payFee }}</i>
                </span>
                <span>(含邮费{{ item.postFee }}元)</span>
            </div>

            <div class="buttonGroup">
                <span class="span-text" v-if="item.isRefundFlag && item.orderStatusName == '备货中'"> 可退款订单</span>
                <span class="span-text" v-if="item.orderStatusName == '备货中'"> 可改尺码订单</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ApiOrderItem",
    data() {
        return {

        };
    },

    props: {
        item: {
            default: () => { return {} }
        },
        type:{
           default:()=>{return ''}
        }
    },
    filters: {
        orderType(val) {
            switch (val) {
                case "1":
                    return "等待买家付款";
                case "2":
                    return "生产中";
                case "3":
                    return "配送中";
                case "4":
                    return "部分发货";
                case "5":
                    return "已签收";
                case "6":
                    return "退款成功";
                case "7":
                    return "取消订单";
            }
        },
    },
    methods: {
        goToDetail() {
            this.$router.push('/order/detail/' + this.item.id)
        },

        btnClick() {
            // TODO
        }

    },
};
</script>
<style lang="less" scoped>
.orderItem {
    background: #FFFFFF;
    border-radius: 8px;
    margin: 10px;
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        padding: 0 18px;
        &::after {
            border-color: #EEEEEE !important;
        }
        .left {
            font-size: 12px;
            color: #666666;
        }

        .state {
            font-size: 12px;
            color: #DE4547;
            &.yellow {
                color: #E7AC58;
            }
            &.gray {
                color: #999999;
            }
        }
    }

    .goodsList {
        padding: 0 18px;


        .more {

            width: 77px;
            height: 64px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            span {
                line-height: 12px;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #212121;
                display: block;
            }

            img {
                height: 8px !important;
                width: 4px !important;
                min-width: 4px !important;
                margin-top: 7px;
            }
        }
        .baseInfo {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 14px 0 0 0;
            .name {
                width: 251px;
                .ys {
                    font-size: 12px;
                    font-weight: 500;
                    color: #F4F4F4;
                    line-height: 1;
                    overflow: hidden;
                    padding: 1px 4px;
                    background: linear-gradient(264deg, #f58130, #ef2514);
                    border-radius: 2px;
                    margin-right: 3px;
                    display: inline-block;
                    vertical-align: bottom;
                }

              .xh {
                width: 32px;
                background: linear-gradient(264deg, #4db3ee, #00A0E9);
                border-radius: 2px;

                font-size: 12px;
                font-weight: 500;
                color: #F4F4F4;
                line-height: 12px;
                text-align: center;
                padding: 2px 0;
                display: inline-block;
                margin-right: 3px;

              }

                line-height: 16px;
                font-size: 12px;
                color: #333333;
            }

            .price {
                margin-top: -3px;
                span {
                    font-size: 11px;
                    font-weight: 500;
                    color: #D53A26;
                    &:last-child {
                        font-size: 13px;
                        margin-left: 2px;
                    }
                }
            }
        }

        .goodsInfo {
            display: flex;
            align-items: center;
            margin-top: 9px;
            &.multiple {
                .imgBox {
                    width: 77px;
                    height: 64px;
                    position: relative;
                    margin-right: 5px;

                    .num {
                        padding: 5px;
                        border-radius: 0 0 5px 0;
                        font-size: 10px;
                        color: #FFFFFF;
                        line-height: 10px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background-color: rgba(27, 27, 27, .5);
                    }
                }
                img {
                    margin-right: 0;
                }
            }
            img {
                width: 77px;
                height: 64px;
                border-radius: 5px;
                min-width: 77px;
                margin-right: 11px;
            }

            .right {
                .sku {
                    background: #EEEEEE;
                    border-radius: 2px;

                    padding: 5px 10px;
                    line-height: 11px;
                    font-size: 10px;
                    color: #666666;

                    display: inline-block;
                }

                .tagList {
                    margin: 7px 0;
                    font-size: 0;
                    .tag {
                        display: inline-block;
                        background: #FFFFFF;
                        border: 1px solid #FF0000;
                        border-radius: 3px;
                        line-height: 10px;
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FF0000;

                        padding: 2px 5px;

                    }
                }

                .time {
                    line-height: 10px;
                    font-size: 10px;
                    color: #E7AC58;
                }
            }
        }

        .orderInfo {
            padding: 24px 0 12px 0;
            text-align: right;
            span {
                line-height: 12px;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #212121;
                &.price {
                    margin: 0 5px
                }
                i {
                    font-style: normal;
                    font-weight: bold;
                    color: #D53A26;
                    &.money {
                        margin-left: 2px;
                        font-size: 15px;
                    }
                }
            }
        }

        .buttonGroup {
            padding-bottom: 15px;
            text-align: left;
            .van-button--mini + .van-button--mini {
                margin-left: 6px;
            }
            .van-button--plain.van-button--info {
                border-color: #999 !important;
            }
            .van-button { padding-left: 14px ; padding-right: 14px; font-size: 12px;}

            .span-text{
                padding: 3px;
                border-radius: 5px;
                font-size: 13px;
                background: #f1f1f1;
                color: #7b7272;
                margin: 0 3px;
            }
        }
    }
}

</style>
